import React from "react"

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contactData: { name: '', email: '', subject: '', message: '' },
      formBtnDisabled: true,
      showMessage: false,
      errorPresent: false,
      errorMessage: ''
    }
  }

  sendMessage = () => {
    if(!validateEmail(this.state.contactData.email)) {
      this.setState({
        errorPresent: true, errorMessage: 'Invalid email'
      })
      return
    }
    if(this.state.contactData.message.length < 25) {
      this.setState({
        errorPresent: true, errorMessage: 'The message is too short. 25 characters minimum'
      })
      return
    }
    fetch(this.sendDataUrl(this.props.sendPath), {
      credentials: 'same-origin', method: 'post'
    })
    this.setState({
      errorPresent: false,
      contactData: { name: '', email: '', subject: '', message: '' },
      showMessage: true,
      formBtnDisabled: true
    })

    function validateEmail(email) {
      const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

      return re.test(String(email).toLowerCase());
    }
  }

  sendDataUrl(path) {
    const params = Object.entries(this.state.contactData).map(arr => {
      const key = encodeURIComponent(`ticket[${arr[0]}]`),
            value = encodeURIComponent(arr[1])
      return [key, value].join('=')
    }).join('&')
    return `${path}.js?${params}`
  }

  changeField(e) {
    const contactData = Object.assign({}, this.state.contactData)
    contactData[e.currentTarget.name] = e.currentTarget.value
    this.updateCustomizationByForm(contactData)
  }

  updateCustomizationByForm(contactData) {
    this.setState({
      contactData: contactData,
      formBtnDisabled: Object.values(contactData).includes(''),
      showMessage: false
    })
  }

  render() {
    return (
      <form data-behavior="contactUsForm">
        <div className="form-control border-0">
          <input id="name" className="control" placeholder="Enter your name"
                 name="name" required onChange={this.changeField.bind(this)}
                 value={this.state.contactData.name} placeholder="Enter your name"/>
          <label className="mt10" htmlFor="name"></label>
        </div>
        <div className="form-control border-0">
          <input id="email" className="control" placeholder="Enter your email"
                 type="email" name="email" required onChange={this.changeField.bind(this)}
                 value={this.state.contactData.email} placeholder="Enter your email" />
          <label className="mt10" htmlFor="email"></label>
        </div>
        <div className="form-control border-0">
          <input id="subject" name="subject" className="control"
                 onChange={this.changeField.bind(this)} placeholder="Subject"
                 value={this.state.contactData.subject} />
          <label className="mt10" htmlFor="subject"></label>
        </div>
        <div className="form-control border-0">
          <textarea id="message" name="message" className="control" rows="3" placeholder="Message"
                    onChange={this.changeField.bind(this)} value={this.state.contactData.message}>
          </textarea>
          <label className="mt10" htmlFor="message"></label>
        </div>

        <p className={this.state.errorPresent ? "" : 'd-none' }>{this.state.errorMessage}</p>

        <div className="form-control border-0">
          <button type="button" className="btn btn-white btn-lg active"
                  disabled={this.state.formBtnDisabled} onClick={this.sendMessage}>
            Send message
          </button>
        </div>
        <p className={this.state.showMessage ? "" : 'd-none' }>Message sent</p>
      </form>
    )
  }
}
